export default {
    en: {
        'abouttitle': 'ABOUT',
        'aboutdetailtitle': 'What is 360 SELFIE?',
        'aboutdetailpart1':'An event cannot go without capturing the moments. Whether it\'s a corporate event or friends reunion, a wedding or a birthday party, the 360 SELFIE captures the moment in its full panorama, all in motion! ',
        'aboutdetailpart2' : 'Live a unique 360-angle experience by our revolving photo stage and make your event unforgettable with the new generation of selfies!',
        '360turn': '360 TURN',
        'livephotos': 'LIVE PHOTOS',
        'instantsharing':'INSTANT SHARING',
        'detailstitle':'DETAILS',
        'steponthestage':'STEP ON THE STAGE!',
        'steponthestagedetail':'The stage allows you to try 360 SELFIE in a group!',
        'pose':'POSE!',
        'posedetail':'As the camera turns around, all you have to do is have fun and stay in the perfect pose!',
        'shareit':'CHECK IT, SHARE IT!',
        'shareitdetail':'You can immediately see yourself in the form of a 360-angle motion photo that you can send to yourself and share it right away!',
        'contacttitle' : 'CONTACT',
        'writeus': 'CONTACT US',
        'writeusdetail': 'Write an e-mail and we will contact you shortly.',
        'sendtitle': 'SEND ME A QUOTE',
        'allrights':' All rights reserved.',
        'aboutmenu':'ABOUT',
        'detailmenu': 'DETAILS',
        'eventmenu': 'GALLERY',
        'contactmenu': 'CONTACT',
        'eventtitle':'Events',
        'check':'CHECK',
        'signin':'Sign in',
        'signindetail':'Type that token what you get on the event!',
        'close':'close',
        'submit':'submit',
        'notfound': 'Oops, i cannot find this 360selfie. :('
    },
    hu: {
        'abouttitle': 'RÓLUNK',
        'aboutdetailtitle': 'Mi is az a 360 SELFIE?',
        'aboutdetailpart1':'Egy rendezvény nem telhet el a pillanatok megörökítése nélkül. Legyen szó baráti vagy céges összejövetelről, esküvőről vagy születésnapról, a 360 SELFIE teljes panorámájában ragadja meg a pillanatot, mindezt mozgókép formájában!',
        'aboutdetailpart2' : 'Tapasztald meg a forgó fotószínpadunk általi egyedülálló 360 fokos élményt és tedd felejthetetlenné rendezvényed a szelfik új generációjával!',
        '360turn': '360 FOKOS FORDULAT',
        'livephotos': 'ÉLŐ FÉNYKÉPEK',
        'instantsharing':'AZONNALI MEGOSZTÁS',
        'detailstitle':'RÉSZLETEK',
        'steponthestage':'LÉPJ A SZÍNPADRA!',
        'steponthestagedetail':'A színpad lehetővé teszi, hogy egyszerre akár többen is kipróbáljátok a 360 SELFIE-t!',
        'pose':'PÓZOLJ!',
        'posedetail':'Amíg körbehalad a kamera, neked csak annyi a dolgod, hogy érezd jól magad és vedd fel a tökéletes pózt!',
        'shareit':'NÉZD MEG, OSZD MEG!',
        'shareitdetail':'Azonnal megtekintheted az elkészült 360 fokos mozgó szelfit, amit elküldhetsz magadnak és rögtön meg is oszthatsz!',
        'contacttitle' : 'KAPCSOLAT',
        'writeus': 'ÍRJ NEKÜNK!',
        'writeusdetail': 'Írj levelet és mi hamarosan felvesszük veled a kapcsolatot.',
        'sendtitle': 'ÁRAJÁNLATKÉRÉS',
        'allrights':' MINDEN JOG FENNTARTVA',
        'aboutmenu':'RÓLUNK',
        'detailmenu': 'RÉSZLETEK',
        'eventmenu': 'GALÉRIA',
        'contactmenu': 'KAPCSOLAT',
        'eventtitle':'Események',
        'check':'MEGTEKINTÉS',
        'signin':'Belépés',
        'signindetail':' Írd be az eseményen kapott tokent a videók megtekintéséhez!',
        'close':'bezár',
        'submit':'megerősít',
        'notfound': 'Upsz, nem találom ezt a 360selfie-t. :( '
    }
}