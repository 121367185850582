import React, { Component } from 'react';
import {Provider} from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import AppIntl from './AppIntl'

import {init as firebaseInit} from './Firebase'

import './App.css';
import store from './store'; 

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000001',
      light: '#a3e1c6',
      dark: '#000001'
    },
    secondary: {
      main: '#a3e1c6',
      light: '#a3e1c6',
      contrastText: '#FFCC00'
    },
  },
  typography: {
    useNextVarients: true
  },
  shadows:["none"],
  typography: {
    "fontFamily": "\"Montserrat\", sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
})

class App extends Component {
  constructor(props) {
    super(props)
    firebaseInit()
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <div className="App">
            <AppIntl/>
          </div>
        </Provider>
      </MuiThemeProvider>
    );
  }
  
}

export default(App);




