import * as firebase from 'firebase'
export const init = () => {
  let config = {
    apiKey: '',
    authDomain: '',
    projectId: '',
    databaseURL: '',
    storageBucket: '',
    messagingSenderId: ''
  }
  firebase.initializeApp(config)
}