import axios from 'axios';
import {GET_EVENTS, GET_ME, OPEN_ALERT,CLOSE_ALERT,SEND_MAIL,GET_MY_EVENTS, ERROR} from './types';

import * as Firebase from 'firebase'
export const LOCALE_SET = 'LOCALE_SET';

let baseURL = ""
const bucketName = "";

export const getEvents = () => dispatch => {
    axios.get(baseURL+'/events')
        .then(res =>
            dispatch({
                type: GET_EVENTS,
                payload:res.data
            }))
        .catch(err=>
            dispatch({
                type: ERROR,
                payload: err.data
            })
    );
}

export const getMyEvents = () => dispatch => {
    axios.get(baseURL+'/events/me',{withCredentials:true})
        .then(res =>
            dispatch({
                type: GET_MY_EVENTS,
                payload:res.data
            }))
        .catch(err=>
            dispatch({
                type: ERROR,
                payload: err.data
        })
    );
}

export const getMe = () => dispatch => {
    axios.get(baseURL+'/auth/me',{withCredentials:true})
        .then(res =>
            dispatch({
                type: GET_ME,
                payload:res.data
            }))
        .catch(err=>
            dispatch({
                type: ERROR,
                payload: err.data
            })
    );
}

export const deleteEvent = (id) => dispatch => {
    axios.delete(baseURL+'/events/'+id).then(res => {
        axios.get(baseURL+'/events/me',{withCredentials:true})
            .then(res =>
                dispatch({
                    type: GET_MY_EVENTS,
                    payload:res.data
                }))
            .catch(err =>
                dispatch({
                    type: ERROR,
                    payload: err.data
                })
            )
        }).catch(err=>
            dispatch({
                type: ERROR,
                payload: err.data
            })
        );
}

export const submitEvent = (data) => (dispatch) => {

        const frame = `frame-${Date.now()}.png`;
        const storageName =`frames/${frame}`;

        const body = {
            eventData: {
                name:data.eventName,
                frame,
                frameURL: `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodeURIComponent(storageName)}?alt=media`
            },
        };

        return axios.post(baseURL+'/events',JSON.stringify(body),
                    {
                        headers: { 'content-type': 'application/json'},
                        withCredentials:true
                    }
                )
                .then(res => { 
                   const storageRef = Firebase.storage().ref();
                    storageRef.child(`frames/${frame}`).put(data.file).then( () => {
                        axios.get(baseURL+'/events/me',{withCredentials:true})
                            .then(res =>
                                dispatch({
                                    type: GET_MY_EVENTS,
                                    payload:res.data
                                }))
                            .catch(err=>
                                dispatch({
                                    type: ERROR,
                                    payload: err.data
                                })
                            )
                        }); 
                    }
                )
            .catch(err=>{
                console.log(err)
                return dispatch({
                    type: ERROR,
                    payload: err.data
                })
            });
}



export function openAlert(message) {
    return {
        type: OPEN_ALERT,
        message: message
    }
}
  
export function closeAlert() {
    return {
    type: CLOSE_ALERT
    }
}

//GET EVENTS
export const sendMail = (data) => dispatch => {
    axios.post('EMAIL URL',JSON.stringify(data)
        .then(res =>
            dispatch({
                type: SEND_MAIL,
                payload:res.data
            }))
        .catch(err=>
            dispatch({
                type: ERROR,
                payload: err.data
            })
        ))
}

export const localeSet = lang  => ({
    type: LOCALE_SET,
    lang
})

export const setLocale = lang => dispatch => { 
    localStorage.alhubLang=lang
    dispatch(localeSet(lang))
}
